<template>
  <div class="pa-10">
    <title-page icon="mdi-wifi-strength-off-outline">
      {{ $t('reports.offline.offline') }}
    </title-page>

    <v-breadcrumbs
        :items="[
            {text: $t('reports.reports'), to: {name: 'reports'}, exact: true},
            {text: $t('reports.offline.offline')},
        ]"
        divider=">"
    ></v-breadcrumbs>

    <v-card>
      <v-card-title>
        {{ $t('search') }}
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="search">
          <v-row>
            <v-col md="3">
              <date-picker
                  :label="$t('period')"
                  range
                  clearable
                  v-model="filters.dates"
              />
            </v-col>

            <v-col md="3">
              <tree-select-organization
                  :label="$t('organization-id')"
                  clearable
                  v-model="filters.organizationId"
              />
            </v-col>
            <v-col md="3">
              <textfield
                  :label="$t('evse-id')"
                  clearable
                  v-model="filters.evseId"
              />
            </v-col>

            <v-col md="3">
              <v-select
                  :label="$t('duration')"
                  :items="[
                      $t('all'),
                      '> 1h',
                      '> 6h',
                      '> 12h',
                      '> 24h',
                      '> 48h',
                  ]"
                  v-model="filters.duration"
              />
            </v-col>

            <v-col md="3">
              <v-switch
                  :label="$t('disconnected')"
                  v-model="filters.disconnected"
              />
            </v-col>

          </v-row>
          <v-row>
            <v-col class="text-right">
              <v-btn type="button" small class="mr-3" @click="clear">
                {{ $t('reset')}}
              </v-btn>
              <v-btn type="submit" color="primary" small>
                {{ $t("search") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <div class="my-10"></div>

    <v-row>
      <v-col>
        <v-data-table
            :headers="headers"
            :items="items"
            :options.sync="options"
            :server-items-length="total"
            :item-class="itemClass"
        >

          <template #item.disconnectedAt="{value}">
            {{ formatDate(value, $t('format_datetime')) }}
          </template>
          <template #item.reconnectedAt="{value}">
            {{ formatDate(value, $t('format_datetime')) }}
          </template>

          <template #item.organizationId="{value}">
            <span style="white-space: nowrap" v-if="value">
              {{ value }}
              <v-btn small icon @click="copyToClipboard(value)"><v-icon small>mdi-content-copy</v-icon></v-btn>
            </span>

          </template>

          <template #item.chargingPointId="{value}">
            <span style="white-space: nowrap" v-if="value">
              {{ value }}
              <v-btn small icon @click="copyToClipboard(value)"><v-icon small>mdi-content-copy</v-icon></v-btn>
            </span>
          </template>

          <template #item.chargingPointIdentifier="{value}">
            <span style="white-space: nowrap" v-if="value">
              {{ value }}
              <v-btn small icon @click="copyToClipboard(value)"><v-icon small>mdi-content-copy</v-icon></v-btn>
            </span>
          </template>

          <template #item.evseIds="{value}" >
            <template v-if="value">
              <div style="white-space: nowrap" v-for="evseId in value" :key="evseId">
                {{ evseId }}
                <v-btn small icon @click="copyToClipboard(evseId)"><v-icon small>mdi-content-copy</v-icon></v-btn>
              </div>
            </template>
          </template>

          <template #item.duration="{value}">
            <span style="white-space: nowrap" v-if="value">
              {{ formatDuration(value) }}
            </span>
          </template>




        </v-data-table>
      </v-col>
    </v-row>


    <v-row class="mb-5">
      <v-col>
        <v-btn @click="downloadCsv" :loading="loadingExport" :disabled="loadingExport" type="button">
          <v-icon left>mdi-file-delimited-outline</v-icon>
          {{ $t("export") }}
        </v-btn>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import ReportRepository from "@repository/ReportRepository";
import dayjs from "dayjs";
import TreeSelectOrganization from "@blocks/Select/TreeSelectOrganization.vue";

export default {
  components: {TreeSelectOrganization},
  data() {
    return {
      loadingExport: false,
      total: 0,
      items: [],
      filters: {
        duration: 'Tous'
      },
      options: null,
    }
  },

  mounted() {

  },

  watch: {
    options() {
      this.loadData()
    }
  },


  computed: {
    headers() {
      return [
        {"text": this.$t('organization.organization'), value: 'organizationName'},
        {"text": this.$t('charging-point-id'), value: 'chargingPointId'},
        {"text": this.$t('charging-point-identifier'), value: 'chargingPointIdentifier'},
        {"text": this.$t('evse-id'), value: 'evseIds'},
        {"text": this.$t('reports.offline.disconnected-at'), value: 'disconnectedAt'},
        {"text": this.$t('reports.offline.reconnected-at'), value: 'reconnectedAt'},
        {"text": this.$t('duration'), value: 'duration'},
        {"text": this.$t('vendor'), value: 'vendor', width: 150},
        {"text": this.$t('model'), value: 'model', width: 150},
        {"text": this.$t('firmware'), value: 'firmware'},
        {"text": this.$t('organization-id'), value: 'organizationId'},

      ]
    },
  },


  methods: {

    getQuery() {
      let data = this.cloneObj(this.filters)

      if (data.dates) {
        data.dateFrom = dayjs(data.dates[0], 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss')
        data.dateTo = dayjs(data.dates[1], 'YYYY-MM-DD').format('YYYY-MM-DD 23:59:59')
        delete data.dates
      }

      if (this.options.itemsPerPage > -1) {
        data.offset = (this.options.page * this.options.itemsPerPage) - this.options.itemsPerPage
      }
      data.limit = this.options.itemsPerPage


      if (this.options.sortBy.length > 0) {
        data.orderBy = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0) {
        data.orderDirection = this.options.sortDesc[0] ? 'desc' : 'asc'
      }
      return data;
    },


    search() {
      if(this.options.page > 1) {
        this.options.page = 1
      } else {
        this.loadData();
      }
    },

    loadData() {
      this.showLoading(true)
      ReportRepository.offline(this.getQuery()).then(response => {
        this.total = response.total
        this.items = response.items
        this.showLoading(false)
      }).catch(e => {
        this.$dialog.notify.error(e.response?.data?.message ?? e.message)
        this.showLoading(false)
      })
    },


    clear() {
      this.filters = {}
      this.loadData();
    },


    downloadCsv() {
      this.loadingExport = true
      ReportRepository.offlineExport(this.getQuery()).then(() => {
        this.loadingExport = false
      }).catch(e => {
        this.$dialog.notify.error(e.response?.data?.message ?? e.message)
        this.loadingExport = false
      })
    },

    itemClass(item) {
      if(!item.reconnectedAt) {
        return "red--text"
      }
    },


  }
}
</script>

<style lang="scss">

</style>
