<template>
  <div class="pa-10">
    <title-page icon="mdi-lightning-bolt">
      {{ $t('recharge-service.recharge-service') }}
    </title-page>

    <v-breadcrumbs
        :items="[
            {text: $t('reports.reports'), to: {name: 'reports'}, exact: true},
            {text: $t('recharge-service.recharge-service')},
        ]"
        divider=">"
    ></v-breadcrumbs>

    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <date-picker
                label="Date"
                v-model="filter.range"
                range
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row  v-if="stats">
      <v-col cols="6">
        <v-card>
          <v-card-text>

            <v-row dense>
              <v-col cols="4">
                {{ $t('expense-refund.period') }}
              </v-col>
              <v-col cols="8">
                {{ periodName }}
              </v-col>
            </v-row>

            <v-divider class="my-3"></v-divider>

            <v-row dense>
              <v-col cols="4">
                {{ $t('expense-refund.charge-count') }}
              </v-col>
              <v-col cols="8">
                {{ stats.nbLines }}
              </v-col>
            </v-row>

          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card>
          <v-card-text>

            <v-row dense>
              <v-col cols="4">
                {{ $t('charging-point.consumed-energy') }}
              </v-col>
              <v-col cols="8">
                {{ numberFormat(stats.energy/1000, 3) }} kWh
              </v-col>
            </v-row>

            <v-divider class="my-3"></v-divider>

            <v-row dense>
              <v-col cols="4">
                <b>{{ $t('amount') }}</b>
              </v-col>
              <v-col cols="8">
                <b>{{ numberFormat(stats.amount) }} €</b>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


    <!-- List -->
    <v-row no-gutters class="mt-10">
      <v-col>
        <v-data-table
            :headers="headers"
            :items="lines"
            :server-items-length="total"
            :options.sync="options"
            :loading="loading"
        >

          <template #item.startTime="{item}">
            {{ item.startTime|formatDate }}
          </template>

          <template #item.chargingPoint.name="{item}">
            <router-link :to="{name: 'charging-points.detail', params: {id: item.chargingPoint.id}}">
              {{ item.chargingPoint.name }}
            </router-link>
          </template>

          <template #item.organization.name="{item}">
            <router-link :to="{name: 'organizations.energy-provider.period.lines', params: {id: item.organization.id, periodId: item.energyProviderPeriodId}}">
              {{ item.organization.name }}
            </router-link>
          </template>

          <template #item.stopTime="{item}">
            {{ item.stopTime|formatDate }}
          </template>

          <template #item.duration="{value}">
            {{ formatDuration(value) }}
          </template>

          <template #item.customer.lastname="{item}">
            <router-link v-if="item.customer?.lastname" :to="{name: 'customer.detail', params: {id: item.customer.id}}">
              {{  item.customer.firstname + ' ' + item.customer.lastname }}
            </router-link>
            <template v-else>
              {{ item.customer.evcoId }}
            </template>
          </template>

          <template #item.energy="{value}">
            {{ numberFormat(value/ 1000, 3) }} kWh
          </template>

          <template #item.amount="{value}">
            {{ numberFormat(value) }} €
          </template>



        </v-data-table>

      </v-col>
    </v-row>

    <v-btn dark small rounded color="#758093" @click="download">
      <v-icon left>mdi-file-excel</v-icon>
      {{ $t('export') }}
    </v-btn>

  </div>
</template>

<script>
import EnergyProviderRepository from "@repository/EnergyProviderRepository";
import dayjs from "dayjs";

export default {
  data() {
    return {
      loading: false,
      options: null,
      filter: {
        range: [dayjs().subtract(1, 'month').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
      },
      total: 0,
      lines: [],
      stats: null,
    }
  },

  mounted() {

  },

  watch: {
    options() {
      this.loadData()
    },

    filter: {
      handler() {
        this.loadData()
      },
      deep: true
    }

  },

  computed: {
    headers() {
      return [
        {text: this.$t("usages.start-time"), value: 'startTime'},
        {text: this.$t("usages.end-time"), value: 'stopTime'},
        {text: this.$tc('organization.organization'), value: 'organization.name'},
        {text: this.$tc('charging-point.charging-point'), value: 'chargingPoint.name'},
        {text: this.$tc('customer.customer'), value: 'customer.lastname'},
        {text: this.$tc('duration'), value: 'duration'},
        {text: this.$tc('energy'), value: 'energy'},
        {text: this.$tc('price-ht'), value: 'amount'},
      ]
    },

    periodName() {
      if(this.filter.range.length > 0) {
        return dayjs(this.filter.range[0]).format(this.$t('format_date')) + ' - ' + dayjs(this.filter.range[1]).format(this.$t('format_date'))
      }

      return '-'
    }
  },

  methods: {
    loadData() {

      this.loading = true

      const data = {
        dateFrom: this.filter.range[0],
        dateTo: this.filter.range[1],
      }

      if (this.options.itemsPerPage > -1) {
        data.offset = (this.options.page * this.options.itemsPerPage) - this.options.itemsPerPage
      }
      data.limit = this.options.itemsPerPage

      if (this.options.sortBy.length > 0) {
        data.orderBy = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0) {
        data.orderDirection = this.options.sortDesc[0] ? 'desc' : 'asc'
      }

      EnergyProviderRepository.lines(data).then(response => {
        this.stats = response.stats
        this.lines = response.lines
        this.total = response.total
        this.loading = false
      }).catch(err => {
        this.notifyError(err)
        this.loading = false
      })
    },


    download() {
      this.showLoading(true)
      const data = {
        dateFrom: this.filter.range[0],
        dateTo: this.filter.range[1],
      }
      EnergyProviderRepository.exportLines(data).then(response => {
        this.showLoading(false)
      }).catch(err => {
        this.notifyError(err)
        this.showLoading(false)
      })
    }


  },
}
</script>

<style lang="scss">

</style>
